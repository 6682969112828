
import CategoryBanners from '@/components/category_banners'
import CatalogCard from '../components/catalog_card.vue'
import SupplierSelect from '@/components/supplier_select'
import PageNavbar from '@/components/page_navbar'
import _ from 'underscore'
import LoadingSpinner from '@/components/loading_spinner'

export default {
  components: {
    CategoryBanners,
    CatalogCard,
    SupplierSelect,
    PageNavbar,
    LoadingSpinner
  }

  computed: {
    name: ->
      @$store.state.category.name

    id: ->
      @$store.state.category.id

    items: ->
      _.sortBy(@categories.concat(@products), 'name')

    categories: ->
      _.map(@$store.state.category.categories, (c) ->
        c.target = 'category'; c
      )

    products: ->
      _.map(@$store.state.category.products, (p) ->
        p.target = 'product'; p
      )

    offers: ->
      @$store.state.category_banners.offers

    loading: ->
      @$store.state.category.loading || @$store.state.category_banners.loading

    error: ->
      @$store.state.category.error

    supplier_id: ->
      @$store.state.suppliers.current_id
  }

  created: ->
    @fetch_content()

  destroyed: ->
    @$store.commit('category/clear')
    @$store.commit('category_banners/clear')

  methods: {
    fetch_content: ->
      @$store.dispatch('category/fetch', @$route.params)
        .finally =>
          @set_page_title()
      @fetch_banners() if @id

    fetch_banners: ->
      @$store.dispatch('category_banners/fetch', {
        category_id: @id,
        supplier_id: @supplier_id
      })

    set_page_title: ->
      document.title = @name
      @$store.dispatch('analytics/page_view')
  }

  watch: {
    $route: ->
      @fetch_content()

    id: (id) ->
      @fetch_banners()

    error: (error) ->
      window.history.back() if !!error.length
  }
}
